import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function Liqpay() {
    const location = useLocation();
    const navigate = useNavigate();
    const paymentData = location.state?.paymentData;

    useEffect(() => {
        if (!paymentData) {
            navigate(-1);
        }
    }, [paymentData, navigate]);

    if (!paymentData) {
        return (
            <div>
                <p>Помилка. Данні для оплати не отрамані.</p>
                <button onClick={() => navigate(-1)}>Повернутися назад</button>
            </div>
        );
    }

    console.log(paymentData);
    return (
        <>
            <h3>
                {paymentData.description} {paymentData.amount} {paymentData.currency}
            </h3>
            <form method="POST" action="https://www.liqpay.ua/api/3/checkout" acceptCharset="utf-8">
                <input type="hidden" name="data" value={paymentData.data} />
                <input type="hidden" name="signature" value={paymentData.signature} />
                <input type="image" src="//static.liqpay.ua/buttons/payUk.png" alt="Оплатить через LiqPay" />
            </form>
        </>
    );
}

export default Liqpay;
