import { useCallback, useContext, useEffect, useState } from 'react';
import style from './MyOrders.module.css';
import { fetchData } from '../../data/fetchData';
import AppContext from '../../context/appContext';
import { useNavigate } from 'react-router-dom';

function MyOrders({ myOrdersUrl, initData }) {
    const { products, setCart } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [orders, setOrders] = useState([]);
    const navigate = useNavigate();

    // const orderData =
    //     '{"success":true,"orders":[{"id":"4fb27a20-b250-11ef-9c2a-a18973cfb785","date":"04.12.2024","products":[{"id":"000000120","title":"Вода моршинська 0,5","amount":1,"price":9999,"sum":9999},{"id":"000000120","title":"Вода моршинська 0,5","amount":1,"price":21,"sum":21}],"sum":21,"paid":true},{"id":"4fb27a20-b250-11ef-9c2a-a18973cfb785","date":"04.12.2024","products":[{"id":"000000120","title":"Вода моршинська 0,5","amount":1,"price":21,"sum":21}],"sum":21,"paid":true}]}';

    const handleError = (message) => {
        setError(message);
        setLoading(false);
    };

    const getMyOrders = useCallback(async () => {
        setLoading(true);
        setError('');
        try {
            const responseData = await fetchData(myOrdersUrl, 'POST', { initData });
            if (responseData.success) {
                setOrders(responseData.orders);
            } else {
                handleError(responseData.error || 'Помилка при отриманні замовлень. Спробуйте ще раз.');
            }
        } catch (error) {
            handleError('Сталася помилка при отриманні замовлень. Будь ласка, спробуйте ще раз.');
        } finally {
            setLoading(false);
        }
    }, [initData, myOrdersUrl]);

    useEffect(() => {
        getMyOrders();
        // setOrders(JSON.parse(orderData).orders);
    }, [getMyOrders]);

    const handleRepeat = (orderId) => {
        const previousOrder = orders.find((order) => order.id === orderId);
        if (!previousOrder) return;

        const productsMap = products.reduce((acc, product) => {
            acc[product.id] = product;
            return acc;
        }, {});

        const newCart = previousOrder.products.reduce((acc, orderProduct) => {
            const currentProduct = productsMap[orderProduct.id];
            if (currentProduct) {
                const sum = Math.round(currentProduct.price * orderProduct.amount * 100) / 100;
                const { id, price, imgUrl, title, discountForbidden, noDiscountPrice, discount } = currentProduct;
                acc.push({
                    id,
                    amount: orderProduct.amount,
                    price,
                    sum,
                    imgUrl,
                    title,
                    related: false,
                    discountForbidden,
                    noDiscountPrice,
                    discount,
                });
            }
            return acc;
        }, []);

        setCart(newCart);
        if (newCart && newCart.length > 0) {
            navigate('/cart');
        }
    };

    const ordersContent = () => {
        if (loading) {
            return <p className={style.loading}>Завантаження...</p>;
        }

        if (error) {
            return <p className={style.error}>{error}</p>;
        }

        if (orders.length === 0) {
            return <p className={style.noOrders}>У вас немає замовлень.</p>;
        }

        return (
            <div className={style.container}>
                <h3 className={style.ordersTitle}>Мої замовлення</h3>
                <ul className={style.ordersList}>
                    {orders.map((order) => (
                        <li key={order.id} className={style.orderItem}>
                            <div className={style.orderHeader}>
                                <strong>Дата:</strong> {order.date}
                                <button className={style.repeatButton} onClick={() => handleRepeat(order.id)}>
                                    Повторити
                                </button>
                            </div>

                            <div className={style.orderDetails}>
                                {order.products.map((product, index) => (
                                    <li key={product.id} className={style.product}>
                                        <span className={style.productNumber}>{index + 1}.</span>
                                        <span className={style.productTitle}>{product.title}</span>
                                        <span className={style.productAmount}>{product.amount}</span>
                                        <span className={style.productSum}>{product.price}₴</span>
                                        <span className={style.productSum}>{product.sum}₴</span>
                                    </li>
                                    // <div key={product.id} className={style.product}>
                                    //     <span className={style.productNumber}>{index + 1}.</span>
                                    //     <span className={style.productTitle}>{product.title}</span>
                                    //     <span className={style.productAmount}>{product.amount}</span>
                                    //     <span className={style.productAmount}>{product.price}</span>
                                    //     <span className={style.productAmount}>{product.sum}</span>
                                    // </div>
                                ))}
                                <div className={style.totalSum}>
                                    <strong>Сума замовлення:</strong> {order.sum}₴
                                </div>
                            </div>
                            <hr className={style.separator} />
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    return ordersContent();
}

export default MyOrders;
